import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SelectInternal from 'react-select/dist/declarations/src/Select';

import { ChevronDownIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Select, {
    components,
    ControlProps,
    CSSObjectWithLabel,
    GroupBase,
    MenuListProps,
    OptionProps,
    Options,
    OptionsOrGroups,
    SingleValue,
} from 'react-select';
import Button from './ButtonNew';
import TooltipContent from './TooltipContent';
import { isEllipsisActive } from './utils';
import { useOutsideClick } from '../hooks/useOutsideClick';

export const EmptySelectValue = { value: '', label: '' };
export const DROPDOWN_PLACEHOLDER = '-- Select --';

export type DropdownOption = {
    readonly label: string;
    readonly value: string;
    readonly isDefault?: boolean;
};

declare module 'react-select/dist/declarations/src/Select' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
        setMenuIsOpen?: (value: boolean) => void;
        isEditable?: boolean;
        isEditing?: boolean;
        setIsEditing?: (value: boolean) => void;
        onCreateOption?: (value: string) => void;
        onEditOption?: (value: SingleValue<DropdownOption>) => void;
        onDeleteOption?: (value: SingleValue<DropdownOption>) => void;
        newItemText?: string;
        useUppercase?: boolean;
        maxInputLength?: number;
    }
}

type Props = {
    value?: DropdownOption;
    isEditable?: boolean;
    isClearable?: boolean;
    onChange?: (value: SingleValue<DropdownOption>) => void;
    onCreateOption?: (value: string) => void;
    onEditOption?: (value: SingleValue<DropdownOption>) => void;
    onDeleteOption?: (value: SingleValue<DropdownOption>) => void;
    isValidNewOption?: (
        inputValue: string,
        value: Options<DropdownOption>,
        options: OptionsOrGroups<DropdownOption, GroupBase<DropdownOption>>
    ) => boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    options: Array<DropdownOption>;
    placeholder?: string;
    className?: string;
    newItemText?: string;
    useUppercase?: boolean;
    maxInputLength?: number;
    isError?: boolean;
};

type CustomControlProps = {
    isError?: boolean;
    className?: string;
};

function Option({ ...props }: OptionProps<DropdownOption>) {
    const { isEditing, setMenuIsOpen, onDeleteOption, onEditOption, maxInputLength } = props.selectProps;
    const inputRef = useRef<HTMLInputElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [editValue, setEditValue] = useState<string>(props.data?.label || '');
    const [isTruncated, setIsTruncated] = useState(false);
    const isCustom = useMemo(() => props.data?.isDefault === false, [props.data]);
    const isFirstOption = useMemo(
        () => Array.isArray(props.options) && props.options.length > 0 && props.options[0].value === props.data.value,
        [props.options, props.data]
    );

    useEffect(() => {
        setIsTruncated(isEllipsisActive(buttonRef.current));
    }, []);

    useEffect(() => {
        if (!isEditing && editValue !== props.data.label) {
            onEditOption && onEditOption({ label: editValue, value: props.data.value });
        }
    }, [isEditing]);

    const deleteHandler = useCallback(() => {
        onDeleteOption && onDeleteOption(props.data);
    }, [onDeleteOption, props.data]);

    const buttonStyle = props.isSelected ? 'font-bold' : 'font-normal';

    return (
        <div
            className={`caption flex flex-row ${
                props.isSelected ? 'bg-background font-medium text-primary-500' : 'bg-white hover:bg-background'
            } ${!isFirstOption ? 'border-t border-primary-50' : 'border-0'}`}
        >
            {isEditing && isCustom ? (
                <input
                    className="mx-2 my-2 h-10 min-w-0 grow rounded-sm border border-primary-50 bg-white p-0 px-3 py-2 text-sm font-medium focus:border-primary-500"
                    type="text"
                    ref={inputRef}
                    maxLength={maxInputLength}
                    onKeyDown={(e) => {
                        if ([' ', 'Backspace', 'Delete', 'Enter'].includes(e.key)) {
                            e.stopPropagation();
                        }
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        inputRef.current?.focus();
                    }}
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                />
            ) : (
                <div className="group w-full">
                    <Button
                        ref={buttonRef}
                        onClick={() => {
                            props.selectOption(props.data);
                            setMenuIsOpen?.(false);
                        }}
                        className={`w-full truncate text-left transition-colors ${buttonStyle} !p-4`}
                        buttonStyle="text"
                        useUppercase={false}
                        textColor={props.isSelected ? 'text-primary-500' : 'text-black'}
                    >
                        {props.label}
                    </Button>
                    {isTruncated && <TooltipContent className="-mt-10">{props.label}</TooltipContent>}
                </div>
            )}
            {isCustom && onDeleteOption && isEditing && (
                <div className="flex shrink-0 items-center gap-1">
                    <Button
                        buttonStyle="text"
                        usePadding={false}
                        onClick={deleteHandler}
                        className="group m-auto flex h-7 w-7 cursor-pointer items-center justify-center"
                    >
                        <XMarkIcon className="h-5 w-5 transition-transform group-hover:scale-125" />
                    </Button>
                </div>
            )}
        </div>
    );
}

function NoOptionsMessage() {
    return (
        <div className="flex flex-row border-0 bg-white hover:bg-background">
            <span className="flex w-full items-center justify-center bg-transparent py-3 pl-5 pr-5 text-left text-sm font-normal leading-5">
                No options
            </span>
        </div>
    );
}

function MenuList(props: MenuListProps<DropdownOption>) {
    const { isEditing, setIsEditing, onCreateOption, isEditable, newItemText, useUppercase, maxInputLength } =
        props.selectProps;
    const [isNewItemAdded, setIsNewItemAdded] = useState<boolean>(false);
    const [newValue, setNewValue] = useState<string>('');
    const newValueRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (!isEditing) {
            setIsNewItemAdded(false);
            if (newValue) {
                onCreateOption && onCreateOption(newValue);
                setNewValue('');
            }
        }
    }, [isEditing]);

    return (
        <components.MenuList {...props} className="bg-white !pb-0">
            {props.children}
            {isEditable && (
                <>
                    {isEditing && isNewItemAdded && (
                        <div
                            className={`flex items-center bg-white ${
                                props.options.length > 0 ? 'border-t border-primary-50' : 'border-0'
                            }`}
                        >
                            <input
                                className="my-2 ml-2 mr-9 h-10 min-w-0 grow rounded-sm border border-primary-50 bg-white p-0 px-3 py-2 text-sm font-medium focus:border-primary-500"
                                type="text"
                                placeholder="+ New Value"
                                maxLength={maxInputLength}
                                value={newValue}
                                ref={newValueRef}
                                onKeyDown={(e) => {
                                    if ([' ', 'Backspace', 'Delete', 'Enter'].includes(e.key)) {
                                        e.stopPropagation();
                                    }
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    newValueRef.current?.focus();
                                }}
                                onChange={(e) => setNewValue(e.target.value)}
                            />
                        </div>
                    )}
                    {isEditing && !isNewItemAdded && (
                        <div className="flex items-center border-t border-primary-50 bg-white">
                            <Button
                                className="line flex w-full items-center !p-[19px] text-left text-xs !leading-[18px] transition-colors hover:text-primary-500"
                                buttonStyle="text"
                                useUppercase={useUppercase}
                                textColor="text-primary-500"
                                onClick={() => setIsNewItemAdded(true)}
                            >
                                {newItemText ?? '+ New Value'}
                            </Button>
                        </div>
                    )}
                    <div className="w-full border-t border-primary-50 bg-background p-3">
                        <Button
                            onClick={() => setIsEditing?.(!isEditing)}
                            buttonStyle="primary"
                            buttonSize="sm"
                            fullWidth={false}
                            className=""
                        >
                            {isEditing ? (
                                <span>Apply</span>
                            ) : (
                                <div className="column flex w-full items-center justify-center gap-2">
                                    <PencilIcon className="h-4 w-4" />
                                    <span>Edit Labels</span>
                                </div>
                            )}
                        </Button>
                    </div>
                </>
            )}
        </components.MenuList>
    );
}

function Control(customProps: CustomControlProps) {
    return function Control(props: ControlProps<DropdownOption>) {
        const { isError, className = '' } = customProps;
        const { isClearable, menuIsOpen, setMenuIsOpen, placeholder } = props.selectProps;

        const controlRef = useRef<HTMLButtonElement | null>(null);
        const currentValue = useMemo(() => {
            const value = props.getValue();
            if (props.hasValue && Array.isArray(value) && value.length > 0) {
                return value[0];
            } else {
                return EmptySelectValue;
            }
        }, [props]);

        useEffect(() => {
            const handleClickOutside = (e: MouseEvent) => {
                if (controlRef?.current && !controlRef.current.contains(e.target as Node)) {
                    setMenuIsOpen?.(false);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }, [controlRef]);

        useOutsideClick(controlRef, () => setMenuIsOpen?.(false));

        const label = currentValue.label || placeholder; // prevent showing empty string in label

        return (
            <button
                ref={controlRef}
                onClick={() => setMenuIsOpen?.(!menuIsOpen)}
                className={`${className} flex w-full items-center rounded-sm border px-2.5 py-2 text-left text-sm font-medium ${
                    isError ? 'border-error-600 bg-error-50 text-error-600' : 'border-primary-50'
                }`}
            >
                <div className="mr-2 grow truncate text-sm font-medium">{label}</div>
                {isClearable && !!currentValue.value && (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            props.selectOption(EmptySelectValue);
                        }}
                    >
                        <XMarkIcon className="mr-2 h-5 w-5 flex-none transition-transform hover:scale-125" />
                    </button>
                )}
                <ChevronDownIcon
                    className={`h-5 w-5 flex-none transition-transform ${isError ? 'text-error-600' : 'text-black'} ${
                        props.menuIsOpen ? 'rotate-180' : 'rotate-0'
                    }`}
                />
            </button>
        );
    };
}

export default function Dropdown(props: Props) {
    const {
        className,
        value = null,
        isEditable = false,
        isClearable = false,
        onChange = () => {},
        onCreateOption,
        onEditOption,
        onDeleteOption,
        isValidNewOption,
        isDisabled = false,
        isLoading = false,
        options = [],
        newItemText = '+ New Value',
        useUppercase = true,
        maxInputLength = 25,
        placeholder = DROPDOWN_PLACEHOLDER,
        isError = false,
    } = props;
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = () => {
            setMenuIsOpen(false);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (!menuIsOpen) {
            setIsEditing(false);
        }
    }),
        [menuIsOpen];

    const ref = useRef<SelectInternal<DropdownOption, false, GroupBase<DropdownOption>>>(null);

    const prepareDropdownMenuStyles: (base: CSSObjectWithLabel) => CSSObjectWithLabel = (
        provided: CSSObjectWithLabel
    ) => {
        const borderRadius = '3px';
        let width: string | number = 'auto';
        let top: number | undefined = undefined;
        const menuElement = ref.current?.menuListRef;
        const parentElement = menuElement?.parentElement?.parentElement;
        if (parentElement) {
            width = parentElement.clientWidth;
            top = parentElement.offsetHeight + parentElement.offsetTop - window.scrollY;
            if (menuElement && top + menuElement.offsetHeight > window.innerHeight) {
                const marginTop = parseInt(provided.marginTop?.toString() ?? '0');
                const marginBottom = parseInt(provided.marginBottom?.toString() ?? '0');
                const totalHeight = menuElement.offsetHeight + marginTop + marginBottom;
                top = parentElement.offsetTop - window.scrollY - totalHeight;
            }
        }
        return {
            ...provided,
            top,
            zIndex: 50,
            bottom: undefined,
            left: undefined,
            right: undefined,
            width,
            position: 'fixed',
            borderRadius,
        };
    };

    return (
        <Select
            isMulti={false}
            isEditable={isEditable}
            isClearable={isClearable}
            isSearchable={false}
            newItemText={newItemText}
            classNamePrefix="react-select"
            menuIsOpen={menuIsOpen}
            setMenuIsOpen={setMenuIsOpen}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            maxInputLength={maxInputLength}
            components={{
                Option: Option,
                MenuList: MenuList,
                Control: Control({ isError, className }),
                NoOptionsMessage: NoOptionsMessage,
            }}
            value={value}
            onChange={onChange}
            {...(isValidNewOption && { isValidNewOption })}
            {...(onCreateOption && { onCreateOption })}
            {...(onEditOption && { onEditOption })}
            {...(onDeleteOption && { onDeleteOption })}
            isDisabled={isDisabled}
            isLoading={isLoading}
            options={options}
            ref={ref}
            useUppercase={useUppercase}
            styles={{
                menuList: (base) => ({ ...base, position: 'initial' }),
                menu: prepareDropdownMenuStyles,
            }}
            closeMenuOnScroll={true}
            menuPlacement="auto"
            placeholder={placeholder}
        />
    );
}
