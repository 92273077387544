import { MutableRefObject, useEffect } from 'react';

export function useOutsideClick<T extends HTMLElement>(ref: MutableRefObject<T | null>, handler: () => void) {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref?.current && !ref.current.contains(e.target as Node)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);
}
